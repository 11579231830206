import React from "react";
import css from "@styled-system/css";

import { Box } from "../../box";

import headings from "../../theme/src/headings";
import paragraph from "../../theme/src/paragraph";

const RichText = ({ html, color, ...rest }) => {
  if (html) {
    return (
      <Box
        dangerouslySetInnerHTML={{ __html: html }}
        {...rest}
        css={css({
          whiteSpace: "pre-line",
          h1: {
            ...headings["900"],
            marginBottom: "1em",
          },
          h2: {
            ...headings["800"],
            marginBottom: "20px",
          },
          "h3:not(:first-child)": {
            marginTop: "1em",
          },
          h3: {
            ...headings["700"],
            marginBottom: "0.5em",
          },
          h4: {
            ...headings["600"],
          },
          h5: {
            ...headings["500"],
          },
          h6: {
            ...headings["100"],
          },
          p: {
            ...paragraph["500"],
            marginBottom: "0.75em",
          },
          a: {
            wordBreak: "break-word",
            fontWeight: 400,
            // textDecoration: "underline",
            color: "brand.secondary",
            transition: "opacity 200ms ease-out",
          },
          "a:hover": {
            opacity: 0.8,
          },
          strong: {
            fontWeight: 500,
          },
          em: {
            fontStyle: "italic",
          },
          ul: {
            listStyleType: "circle",
            paddingLeft: "3em",
          },
          ol: {
            listStyle: "decimal outside",
            paddingLeft: "3em",
          },
          "> *": {
            color: color ? color : "label.0",
            "&:not(:first-child)": {
              mt: "layout.1",
            },
          },
        })}
      />
    );
  } else {
    return null;
  }
};

export default RichText;
