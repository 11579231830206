import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTheme } from "@emotion/react";
import css from "@styled-system/css";

import {
  Box,
  ColorMode,
  Column,
  Columns,
  Heading,
  Inline,
  Link,
  Logo,
  Text,
  VStack,
  Wrapper,
} from "components";

function Footer() {
  const THEME = useTheme();

  const { page } = useStaticQuery(FOOTER_QUERY);
  const PAGE = page.data;

  return (
    <ColorMode mode="dark">
      <Box data-component-id="footer" as="footer" mt="auto" position="relative">
        {/* Solid angle scrim */}
        <Box
          width="100%"
          pb="10%"
          css={{
            background: `linear-gradient(-4deg, ${THEME.colors.brand.primary} 49.5%, transparent 50%)`,
          }}
        />
        {/* Top section */}
        <Wrapper bg="fill.0" pt="layout.6" pb="layout.6">
          <VStack space="layout.4">
            {/* Logo */}
            <Link href="/">
              <Logo color={"label.0"} />
            </Link>
            {/* CTA and Contact Info */}
            <Columns spaceX="layout.5" spaceY="layout.4">
              <Column width={{ _: 1, md: 4 / 12 }}>
                <VStack space="layout.4">
                  {PAGE.footer_tagline && (
                    <Text color="label.0" size={700}>
                      {PAGE.footer_tagline}
                    </Text>
                  )}
                  <Inline space="layout.2">
                    {PAGE?.phone && (
                      <Box
                        pb="spacing.2"
                        pl="spacing.3"
                        borderLeftWidth={4}
                        borderColor="brand.secondary"
                      >
                        <Link href={"tel:" + PAGE.phone}>
                          <Heading
                            size={500}
                            css={css({
                              transition: "opacity 200ms ease-out",

                              "&.active": {
                                "> span > span": {
                                  color: "brand.secondary",
                                  fontWeight: 500,
                                },
                                "::after": {
                                  width: "100%",
                                },
                              },
                              "&:hover": { opacity: 0.8 },
                            })}
                          >
                            {PAGE.phone}
                          </Heading>
                        </Link>
                      </Box>
                    )}
                    {PAGE?.email && (
                      <Box
                        pb="spacing.2"
                        pl="spacing.3"
                        borderLeftWidth={4}
                        borderColor="brand.secondary"
                      >
                        <Link href={"mailto:" + PAGE.email}>
                          <Heading
                            size={500}
                            css={css({
                              transition: "opacity 200ms ease-out",

                              "&.active": {
                                "> span > span": {
                                  color: "brand.secondary",
                                  fontWeight: 500,
                                },
                                "::after": {
                                  width: "100%",
                                },
                              },
                              "&:hover": { opacity: 0.8 },
                            })}
                          >
                            {PAGE.email}
                          </Heading>
                        </Link>
                      </Box>
                    )}
                  </Inline>
                </VStack>
              </Column>
              {/* Nav Links */}
              {PAGE.footer_links?.[0]?.label && (
                <Column width={{ _: 1, md: 4 / 12 }}>
                  <VStack space="layout.2">
                    {PAGE.footer_links.map((item, i) => {
                      if (item?.link?.url && item?.label)
                        return (
                          <Link href={item.link.url} key={i}>
                            <Heading
                              color="label.0"
                              size={500}
                              css={css({
                                transition: "opacity 200ms ease-out",

                                "&.active": {
                                  "> span > span": {
                                    color: "brand.secondary",
                                    fontWeight: 500,
                                  },
                                  "::after": {
                                    width: "100%",
                                  },
                                },
                                "&:hover": { opacity: 0.8 },
                              })}
                            >
                              {item.label}
                            </Heading>
                          </Link>
                        );
                      else if (item.label)
                        return (
                          <Heading color="label.0" size={500} key={i}>
                            {item.label}
                          </Heading>
                        );
                      else return null;
                    })}
                  </VStack>
                </Column>
              )}
              {/* Secondary Links */}
              {PAGE?.footer_secondary_links?.[0]?.label && (
                <Column width={{ _: 1, md: 4 / 12 }}>
                  <VStack space="layout.2">
                    {PAGE?.footer_secondary_links_heading && (
                      <Heading color="label.0" size={500}>
                        {PAGE?.footer_secondary_links_heading}
                      </Heading>
                    )}
                    <VStack space="layout.1">
                      {PAGE?.footer_secondary_links?.map((item, i) => {
                        if (item?.link?.url && item?.label)
                          return (
                            <Link href={item.link.url} key={i}>
                              <Text
                                color="label.0"
                                size={500}
                                css={css({
                                  transition: "opacity 200ms ease-out",

                                  "&.active": {
                                    "> span > span": {
                                      color: "brand.secondary",
                                      fontWeight: 500,
                                    },
                                    "::after": {
                                      width: "100%",
                                    },
                                  },
                                  "&:hover": { opacity: 0.8 },
                                })}
                              >
                                {item.label}
                              </Text>
                            </Link>
                          );
                        else if (item?.label)
                          return (
                            <Text color="label.0" size={500} key={i}>
                              {item.label}
                            </Text>
                          );
                        else return null;
                      })}
                    </VStack>
                  </VStack>
                </Column>
              )}
            </Columns>
          </VStack>
        </Wrapper>
        {/* Tertiary Links */}
        <Wrapper bg="fill.0" py="layout.2">
          <Inline space="layout.3">
            {PAGE?.footer_tertiary_links?.map((item, i) => {
              if (item?.link?.url && item?.label)
                return (
                  <Link href={item.link.url} key={i}>
                    <Text
                      color="label.1"
                      fontSize={12}
                      css={css({
                        transition: "opacity 200ms ease-out",

                        "&.active": {
                          "> span > span": {
                            color: "brand.secondary",
                            fontWeight: 500,
                          },
                          "::after": {
                            width: "100%",
                          },
                        },
                        "&:hover": { opacity: 0.8 },
                      })}
                    >
                      {item.label}
                    </Text>
                  </Link>
                );
              else if (item?.label)
                return (
                  <Text color="label.1" fontSize={12} key={i}>
                    {item.label}
                  </Text>
                );
              else return null;
            })}
          </Inline>
        </Wrapper>
      </Box>
    </ColorMode>
  );
}

const FOOTER_QUERY = graphql`
  {
    page: prismicNav {
      data {
        footer_tagline
        footer_links {
          label
          link {
            type
            uid
            url
          }
        }
        footer_secondary_links_heading
        footer_secondary_links {
          label
          link {
            url
          }
        }
        footer_tertiary_links {
          label
          link {
            url
          }
        }
        phone
        email
      }
    }
  }
`;

export default Footer;
