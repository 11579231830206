import React, { useEffect, useRef, useState, createElement } from "react";
import * as Reach from "@reach/dialog";
import { animated, config, useChain, useTransition } from "react-spring";
import css from "@styled-system/css";

import { Box, ColorMode, Flex, IconButton } from "components";

// Make Reach UI components animatable with react-spring
const AnimatedDialogOverlay = animated(Reach.DialogOverlay);
const AnimatedDialogContent = animated(Reach.DialogContent);

const overlayStyles = ({ position }) =>
  css({
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 9998,
    display: "flex",
    justifyContent:
      position === "left"
        ? "flex-start"
        : position === "right"
        ? "flex-end"
        : "center",
    alignItems:
      position === "top"
        ? "flex-start"
        : position === "bottom"
        ? "flex-end"
        : "center",
    width: "100%",
    height: "100%",
    p: 0,
    bg: "fill.0",
  });

const contentStyles = ({ position, maxWidth }) =>
  css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height:
      position === "left" ? "100%" : position === "right" ? "100%" : undefined,
    p: 0,
    borderRadius: 0,
    overflow: "hidden",
    bg: "brand.primary",
    boxShadow: `
      0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07)
    `,
    "&:focus": { outline: 0 },
  });

function Panel({ as, children, panel, panelState, setPanelState, ...rest }) {
  const [showPanel, setShowPanel] = useState(false);
  const open = () => {
    if (setPanelState) {
      setPanelState(true);
    }
    setShowPanel(true);
  };
  const close = () => {
    if (setPanelState) {
      setPanelState(false);
    }
    setShowPanel(false);
  };

  const overlayRef = useRef();
  const overlayTransitions = useTransition(showPanel, null, {
    ref: overlayRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  });

  const contentRef = useRef();
  const contentTransitions = useTransition(showPanel, null, {
    ref: contentRef,
    from: {
      opacity: 0,
      // transform: "translateX(-100%)"
    },
    enter: {
      opacity: 1,
      // transform: "translateX(0%)"
    },
    leave: {
      opacity: 0,
      // transform: "translateX(-100%)"
    },
    config: config.stiff,
  });

  useChain(showPanel ? [overlayRef, contentRef] : [contentRef, overlayRef], [
    0,
    showPanel ? 0.1 : 0,
  ]);

  // Allow for external control of panel state
  useEffect(() => {
    setShowPanel(panelState);
  }, [panelState]);

  return (
    <>
      {React.createElement(
        "button",
        { "data-component": "Panel.Button", onClick: open, ...rest },
        children
      )}
      {overlayTransitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <ColorMode mode="dark">
              <AnimatedDialogOverlay
                data-component="Panel.Overlay"
                onDismiss={close}
                css={overlayStyles({
                  position: panel.position ? panel.position : "right",
                })}
                key={key}
                style={styles}
              >
                {contentTransitions.map(
                  ({ item, key, props: styles }) =>
                    item && (
                      <AnimatedDialogContent
                        data-component="Panel.Content"
                        css={contentStyles({
                          position: panel.position ? panel.position : "right",
                          maxWidth: panel.maxWidth ? panel.maxWidth : 512,
                        })}
                        key={key}
                        style={styles}
                      >
                        <Flex
                          data-component="Panel.Inner"
                          flexDirection="column"
                          flex={1}
                          mx={{ _: "spacing.6", md: "layout.3" }}
                          pr={{
                            _: 0,
                            md:
                              panel.position === "top"
                                ? "calc(((100vw - 1400px) / 2) - 32px)"
                                : panel.position === "bottom"
                                ? "calc(((100vw - 1400px) / 2) - 32px)"
                                : panel.position === "right"
                                ? "calc(((100vw - 1400px) / 2) - 32px)"
                                : 0,
                          }}
                          pl={{
                            _: 0,
                            md:
                              panel.position === "top"
                                ? "calc(((100vw - 1400px) / 2) - 32px)"
                                : panel.position === "bottom"
                                ? "calc(((100vw - 1400px) / 2) - 32px)"
                                : panel.position === "left"
                                ? "calc(((100vw - 1400px) / 2) - 32px)"
                                : 0,
                          }}
                        >
                          <Flex
                            justifyContent={
                              panel.position === "left"
                                ? "flex-end"
                                : panel.position === "right"
                                ? "flex-start"
                                : "flex-end"
                            }
                            mt="8px"
                            py={{ _: "spacing.3", md: "layout.3" }}
                          >
                            <Box
                              ml={
                                panel.position === "left"
                                  ? "-10px"
                                  : panel.position === "right"
                                  ? undefined
                                  : "-10px"
                              }
                              mr={
                                panel.position === "right"
                                  ? "-10px"
                                  : panel.position === "right"
                                  ? undefined
                                  : undefined
                              }
                            >
                              <IconButton
                                symbol={"close-line"}
                                intent="default"
                                appearance="minimal"
                                height={40}
                                round
                                onClick={close}
                              />
                            </Box>
                          </Flex>
                          {React.cloneElement(panel.children, {
                            showPanel: showPanel,
                            open: open,
                            close: close,
                          })}
                        </Flex>
                      </AnimatedDialogContent>
                    )
                )}
              </AnimatedDialogOverlay>
            </ColorMode>
          )
      )}
    </>
  );
}

export default Panel;
