import React from "react";

import { Box } from "components";

function Logo({ color = "label.0", ...rest }) {
  return (
    <Box
      data-component-id="logo"
      as="svg"
      width={{ _: 178, md: 200 }}
      height={{ _: 32, md: 36 }}
      viewBox="0 0 178 32"
      fill="none"
      color={color}
      css={{ transition: "color 300ms ease-out" }}
      {...rest}
    >
      <title>Topaz Energy</title>
      <path d="M13.86.267l-5.281 9.888h10.487L13.86.267z" fill="currentColor" />
      <path
        d="M59.66 22.08c-.091 4.288-2.678 6.881-6.816 6.834-3.994-.045-6.561-2.871-6.507-7.165.051-4.098 2.812-6.87 6.787-6.813 4.014.059 6.627 2.913 6.537 7.144zm-3.02-.073c.05-2.669-1.384-4.47-3.588-4.507-2.182-.035-3.698 1.713-3.726 4.299-.03 2.707 1.38 4.49 3.573 4.519 2.272.03 3.691-1.607 3.742-4.311zm13.142-6.387c1.838.656 2.607 1.881 2.611 3.937.004 2.11-.786 3.367-2.636 4.025-.727.259-1.502.413-2.268.52-.728.102-1.473.082-2.346.122v4.383h-2.88V15.425c2.534-.399 5.046-.688 7.519.195zm-1.733 5.85c.975-.194 1.383-.922 1.354-1.898-.027-.959-.542-1.64-1.45-1.784-1.145-.181-2.794.03-2.794.03l.004 3.705s2.004.122 2.886-.052zm-33.65-3.7v-2.478h11.03v2.496l-4.04-.018v10.839h-2.998v-10.84h-3.992zm42.842-2.518h2.966l5.479 13.359h-3.284l-1.017-2.773-5.198-.069-1.138 2.842h-2.99l5.182-13.359zm-.454 8.114h3.889l-1.913-5.198-1.976 5.198zm13.836 2.83l6.771-.002v2.417l-10.314-.003V26.32l6.176-8.569h-5.938v-2.487h9.881l-.057 2.231-6.519 8.7zM6.38 14.066L0 26.245 12.245 32V14.066H6.38z"
        fill="currentColor"
      />
      <path
        d="M104.062 15.229h8.853v1.376h-7.47v4.567h6.683v1.377h-6.683v4.681h7.557v1.377h-8.94V15.229zm11.724 0h1.295l7.715 10.72V15.23h1.347v13.378h-1.101l-7.908-10.97v10.97h-1.348V15.229zm13.824 0h8.853v1.376h-7.47v4.567h6.682v1.377h-6.682v4.681h7.557v1.377h-8.94V15.229zm11.724 0h5.266c1.505 0 2.712.497 3.482 1.338.595.65.944 1.586.944 2.637v.038c0 2.217-1.4 3.517-3.324 3.899l3.762 5.466h-1.697l-3.552-5.199h-3.498v5.198h-1.383V15.23zm5.144 6.823c1.837 0 3.148-1.032 3.148-2.753v-.038c0-1.643-1.154-2.637-3.131-2.637h-3.778v5.428h3.761zm6.965-.096v-.038c0-3.669 2.467-6.917 6.141-6.917 2.064 0 3.341.649 4.566 1.777l-.892 1.146c-.945-.898-1.994-1.529-3.727-1.529-2.712 0-4.654 2.503-4.654 5.485v.038c0 3.192 1.872 5.561 4.847 5.561a5.472 5.472 0 003.534-1.338v-4.682h1.33v5.332c-1.138 1.108-2.852 2.044-4.917 2.044-3.848 0-6.228-3.056-6.228-6.879zm22.449-6.726h1.609l-5.091 8.064v5.313h-1.4v-5.295l-5.091-8.083h1.68l4.128 6.689 4.165-6.688z"
        fill="#E9A12E"
      />
      <path d="M15.4 14.066V32l12.245-5.756-6.38-12.178H15.4z" fill="#E9A12E" />
    </Box>
  );
}

export default Logo;
