function PrismicColor(color) {
  switch (color) {
    case "Grey":
      return "background.1";
    case "Yellow":
      return "brand.secondary";
    default:
      return "background.0";
  }
}

export default PrismicColor;
