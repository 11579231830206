import * as React from "react";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import { CacheProvider } from "@emotion/react";

import { myCache } from "utils";

const DELAY = 300;

export const wrapRootElement = ({ element }) => (
  <CacheProvider value={myCache}>
    <PreviewStoreProvider>{element}</PreviewStoreProvider>
  </CacheProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), DELAY);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      DELAY
    );
  }
  return false;
};
