import { rgba } from "polished";

export default {
  brand: { primary: "#1A3A58", secondary: "#E9A12E" },
  label: [
    rgba(26.0, 58.0, 88.0, 1.0),
    rgba(26.0, 58.0, 88.0, 0.6),
    rgba(26.0, 58.0, 88.0, 0.3),
    rgba(26.0, 58.0, 88.0, 0.18),
  ],
  fill: [
    rgba(120.0, 120.0, 128.0, 0.2),
    rgba(120.0, 120.0, 128.0, 0.16),
    rgba(118.0, 118.0, 128.0, 0.12),
    rgba(116.0, 116.0, 128.0, 0.08),
  ],
  placeholderText: rgba(60.0, 60.0, 67.0, 0.3),
  background: [
    rgba(255.0, 255.0, 255.0, 1.0),
    rgba(242.0, 242.0, 247.0, 1.0),
    rgba(255.0, 255.0, 255.0, 1.0),
  ],
  groupedBackground: [
    rgba(242.0, 242.0, 247.0, 1.0),
    rgba(255.0, 255.0, 255.0, 1.0),
    rgba(242.0, 242.0, 247.0, 1.0),
  ],
  separator: rgba(60.0, 60.0, 67.0, 0.29),
  opaqueSeparator: rgba(198.0, 198.0, 200.0, 1.0),
  link: rgba(0.0, 122.0, 255.0, 1.0),
  tint: {
    red: "#FF3B30",
    orange: "#E9A12E",
    yellow: "#FFCC00",
    green: "#34C759",
    teal: "#5AC8FA",
    blue: "#007AFF",
    navy: "#1A3A58",
    indigo: "#5856D6",
    purple: "#AF52DE",
    pink: "#FF2D55",
  },
  gray: [
    rgba(142.0, 142.0, 147.0, 1.0),
    rgba(174.0, 174.0, 178.0, 1.0),
    rgba(199.0, 199.0, 204.0, 1.0),
    rgba(209.0, 209.0, 214.0, 1.0),
    rgba(229.0, 229.0, 234.0, 1.0),
    rgba(242.0, 242.0, 247.0, 1.0),
  ],
  clear: rgba(0.0, 0.0, 0.0, 0.0),
  fixed: {
    black: rgba(0.0, 0.0, 0.0, 1.0),
    blue: rgba(0.0, 0.0, 255.0, 1.0),
    brown: rgba(153.0, 102.0, 51.0, 1.0),
    cyan: rgba(0.0, 255.0, 255.0, 1.0),
    darkGray: rgba(85.0, 85.0, 85.0, 1.0),
    gray: rgba(127.5, 127.5, 127.5, 1.0),
    green: rgba(0.0, 255.0, 0.0, 1.0),
    lightGray: rgba(170.0, 170.0, 170.0, 1.0),
    magenta: rgba(255.0, 0.0, 255.0, 1.0),
    orange: rgba(255.0, 127.5, 0.0, 1.0),
    purple: rgba(127.5, 0.0, 127.5, 1.0),
    red: rgba(255.0, 0.0, 0.0, 1.0),
    white: rgba(255.0, 255.0, 255.0, 1.0),
    yellow: rgba(255.0, 255.0, 0.0, 1.0),
  },
  modes: {
    dark: {
      brand: { primary: "#1A3A58", secondary: "#E9A12E" },
      label: [
        rgba(255.0, 255.0, 255.0, 1.0),
        rgba(235.0, 235.0, 245.0, 0.6),
        rgba(235.0, 235.0, 245.0, 0.3),
        rgba(235.0, 235.0, 245.0, 0.18),
      ],
      fill: [
        rgba(26.0, 58.0, 88.0, 1.0),
        rgba(26.0, 58.0, 88.0, 0.6),
        rgba(26.0, 58.0, 88.0, 0.3),
        rgba(26.0, 58.0, 88.0, 0.18),
      ],
      placeholderText: rgba(235.0, 235.0, 245.0, 0.3),
      background: [
        rgba(0.0, 0.0, 0.0, 1.0),
        rgba(28.0, 28.0, 30.0, 1.0),
        rgba(44.0, 44.0, 46.0, 1.0),
      ],
      groupedBackground: [
        rgba(0.0, 0.0, 0.0, 1.0),
        rgba(28.0, 28.0, 30.0, 1.0),
        rgba(44.0, 44.0, 46.0, 1.0),
      ],
      separator: rgba(84.0, 84.0, 88.0, 0.6),
      opaqueSeparator: rgba(56.0, 56.0, 58.0, 1.0),
      link: rgba(9.0, 132.0, 255.0, 1.0),
      tint: {
        red: "#FF453A",
        orange: "#E9A12E",
        yellow: "#FFD60A",
        green: "#32D74B",
        teal: "#64D2FF",
        blue: "#0A84FF",
        navy: "#1A3A58",
        indigo: "#5E5CE6",
        purple: "#BF5AF2",
        pink: "#FF375F",
      },
      gray: [
        rgba(142.0, 142.0, 147.0, 1.0),
        rgba(99.0, 99.0, 102.0, 1.0),
        rgba(72.0, 72.0, 74.0, 1.0),
        rgba(58.0, 58.0, 60.0, 1.0),
        rgba(44.0, 44.0, 46.0, 1.0),
        rgba(28.0, 28.0, 30.0, 1.0),
      ],
      clear: rgba(0.0, 0.0, 0.0, 0.0),
      fixed: {
        black: rgba(0.0, 0.0, 0.0, 1.0),
        blue: rgba(0.0, 0.0, 255.0, 1.0),
        brown: rgba(153.0, 102.0, 51.0, 1.0),
        cyan: rgba(0.0, 255.0, 255.0, 1.0),
        darkGray: rgba(85.0, 85.0, 85.0, 1.0),
        gray: rgba(127.5, 127.5, 127.5, 1.0),
        green: rgba(0.0, 255.0, 0.0, 1.0),
        lightGray: rgba(170.0, 170.0, 170.0, 1.0),
        magenta: rgba(255.0, 0.0, 255.0, 1.0),
        orange: rgba(255.0, 127.5, 0.0, 1.0),
        purple: rgba(127.5, 0.0, 127.5, 1.0),
        red: rgba(255.0, 0.0, 0.0, 1.0),
        white: rgba(255.0, 255.0, 255.0, 1.0),
        yellow: rgba(255.0, 255.0, 0.0, 1.0),
      },
    },
  },
};
