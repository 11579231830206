import capsize from "capsize";

import breakpoints from "./breakpoints";

const fontMetrics = {
  capHeight: 718,
  ascent: 925,
  descent: -356,
  lineGap: 0,
  unitsPerEm: 1000,
};

export default {
  "600": {
    fontWeight: 300,
    letterSpacing: "-0.01em",
    ...capsize({
      fontMetrics,
      capHeight: 14,
      lineGap: 10,
    }),
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 16,
        lineGap: 12,
      }),
    },
    [`@media screen and (min-width: ${breakpoints.lg})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 20,
        lineGap: 12,
      }),
    },
  },
  "500": {
    fontWeight: 400,
    letterSpacing: "-0.01em",
    ...capsize({
      fontMetrics,
      capHeight: 12,
      lineGap: 10,
    }),
  },
  "400": {
    fontWeight: 400,
    letterSpacing: "-0.01em",
    ...capsize({
      fontMetrics,
      capHeight: 10,
      lineGap: 10,
    }),
  },
};
