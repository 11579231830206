import React, { useCallback, useEffect, useRef, useState } from "react";
import { createDomMotionComponent, useViewportScroll } from "framer-motion";

const motion = {
  div: createDomMotionComponent("div"),
};

function FadeWrapper({
  children,
  duration,
  delay,
  delayInitialCheck = 300,
  ...rest
}) {
  const fadeVariants = {
    initial: {
      opacity: 0,
      y: 24,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        opacity: {
          duration: duration,
          delay: delay,
        },
        y: {
          duration: duration * 3,
          delay: delay,
          ease: [0.23, 1, 0.32, 1],
        },
      },
    },
  };
  const measuredRef = useRef();
  const [show, setShow] = useState(false);
  const { scrollY } = useViewportScroll();

  const checkHeight = useCallback(() => {
    if (measuredRef && measuredRef.current && !show) {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      if (
        measuredRef.current.getBoundingClientRect().top <
        height - height / 8
      ) {
        setShow(true);
      }
    }
  }, [measuredRef, show]);

  useEffect(() => {
    if (measuredRef && measuredRef.current) {
      // On IE, force show state immediately to avoid performance issues
      if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1
      ) {
        setShow(true);
      } else {
        scrollY.onChange((v) => {
          checkHeight();
        });
      }
      setTimeout(() => {
        checkHeight();
      }, [delayInitialCheck]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measuredRef]);

  return (
    <div ref={measuredRef} {...rest}>
      <motion.div
        variants={fadeVariants}
        initial="initial"
        animate={show ? "enter" : "initial"}
      >
        {children}
      </motion.div>
    </div>
  );
}

FadeWrapper.defaultProps = {
  delay: 0,
  duration: 0.6,
};

export default FadeWrapper;
