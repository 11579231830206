import capsize from "capsize";

import breakpoints from "./breakpoints";

const fontMetrics = {
  capHeight: 718,
  ascent: 925,
  descent: -356,
  lineGap: 0,
  unitsPerEm: 1000,
};

export default {
  "900": {
    fontWeight: 500,
    letterSpacing: "-0.02em",
    ...capsize({
      fontMetrics,
      capHeight: 24,
      lineGap: 8,
    }),
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 32,
        lineGap: 8,
      }),
    },
    [`@media screen and (min-width: ${breakpoints.lg})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 40,
        lineGap: 16,
      }),
    },
    [`@media screen and (min-width: ${breakpoints.max})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 48,
        lineGap: 16,
      }),
    },
  },
  "800": {
    fontWeight: 500,
    letterSpacing: "-0.02em",
    ...capsize({
      fontMetrics,
      capHeight: 20,
      lineGap: 8,
    }),
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 24,
        lineGap: 8,
      }),
    },
  },
  "700": {
    fontWeight: 500,
    letterSpacing: "-0.02em",
    ...capsize({
      fontMetrics,
      capHeight: 16,
      lineGap: 8,
    }),
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 20,
        lineGap: 12,
      }),
    },
    [`@media screen and (min-width: ${breakpoints.lg})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 24,
        lineGap: 16,
      }),
    },
  },
  "600": {
    fontWeight: 500,
    letterSpacing: "-0.02em",
    ...capsize({
      fontMetrics,
      capHeight: 20,
      lineGap: 8,
    }),
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      ...capsize({
        fontMetrics,
        capHeight: 24,
        lineGap: 8,
      }),
    },
  },
  "500": {
    fontWeight: 500,
    letterSpacing: "-0.01em",
    ...capsize({
      fontMetrics,
      capHeight: 12,
      lineGap: 8,
    }),
  },
  "100": {
    fontWeight: 500,
    textTransform: "uppercase",
    letterSpacing: "0.01em",
    ...capsize({
      fontMetrics,
      capHeight: 10,
      lineGap: 8,
    }),
  },
};
