import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import css from "@styled-system/css";

import {
  Box,
  ColorMode,
  Flex,
  Heading,
  HStack,
  IconButton,
  Logo,
  Link,
  Panel,
  Text,
  VStack,
  Wrapper,
} from "components";

const Menu = ({ close, data }) => (
  <Flex justifyContent="center" alignItems="center" py="5vh">
    <VStack as="ul" space="layout.3">
      {data.header_links?.map(({ label, link }, i) => {
        if (link?.url && label)
          return (
            <Heading
              key={i}
              color={"label.0"}
              textAlign="center"
              size={600}
              onClick={close}
            >
              <Link children={label} href={link.url} />
            </Heading>
          );
        else if (label) {
          <Heading key={i} color={"label.0"} textAlign="center" size={600}>
            {label}
          </Heading>;
        }
      })}
    </VStack>
  </Flex>
);

function Header({ path }) {
  const [headerFixed, setHeaderFixed] = useState(false);
  const [color, setColor] = useState("label.0");

  const { page } = useStaticQuery(HEADER_QUERY);
  const { data } = page;

  const { corporate_presentation_file, corporate_presentation_label } = data;

  useEffect(() => {
    setColor("label.0");
  }, [path]);

  useEffect(() => {
    const monitorHeader = () => {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!headerFixed && scrollTop > 0) {
        setHeaderFixed(true);
      }
      if (headerFixed && scrollTop <= 0) {
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", monitorHeader);
    return function cleanup() {
      window.removeEventListener("scroll", monitorHeader);
    };
  }, [headerFixed]);

  //TICKER CODE
  useEffect(() => {
    // NEW CODE
    const script = document.createElement("script");

    script.type = "application/javascript";
    script.src = "https://qmod.quotemedia.com/js/qmodLoader.js";
    script.async = true;
    script.id = "qmod";
    script.setAttribute("data-qmod-wmid", "103788");
    script.setAttribute("data-qmod-env", "app");

    let quoteWrapper = document.getElementById("quoteWrapper");

    quoteWrapper.appendChild(script);
  }, []);

  return (
    <ColorMode mode="dark">
      {/* Stock Ticker and Corporate Presentation Link*/}
      <Wrapper
        bg="brand.secondary"
        py="layout.2"
        css={{ position: "relative", zIndex: 9999 }}
      >
        <Flex alignItems="center" display={{ _: "flex-col", md: "flex" }}>
          <Ticker />
          {corporate_presentation_file?.url && corporate_presentation_label && (
            <CorporatePresentationLink
              corporate_presentation_file={corporate_presentation_file}
              corporate_presentation_label={corporate_presentation_label}
            />
          )}
        </Flex>
      </Wrapper>
      {/* Header */}
      <Box
        className={headerFixed ? "fixed" : ""}
        data-component-id="header"
        as="header"
        position="absolute"
        top={0}
        left={0}
        zIndex={3}
        width={1 / 1}
        py="layout.6"
        css={css({
          transition: "all 300ms ease-out",
          "&.fixed": {
            // top: [-12, -12, 0],
            py: ["layout.2", "layout.2"],
            backgroundColor: "brand.primary",
            position: "fixed",
          },
        })}
      >
        <Wrapper>
          <Flex
            alignItems={"flex-end"}
            justifyContent={{ _: "space-between", lg: "flex-start" }}
            css={{ overflow: "visible" }}
          >
            {/* Home Link */}
            <Box>
              <Link href="/">
                <Logo color={color} />
              </Link>
            </Box>

            {/* Link List */}
            {data.header_links.length > 0 && (
              <>
                <Box
                  py="6px"
                  display={{ _: "none", lg: "block" }}
                  ml="layout.4"
                >
                  <HStack as="ul" space="layout.3">
                    {data.header_links
                      .slice(0, data.header_links.length - 1)
                      .map(({ label, link }, index) => {
                        if (label && link && link.url)
                          return (
                            <Box
                              key={"dataHeaderLinks" + index}
                              className={path === link.url ? "active" : ""}
                              position="relative"
                              color={"label.0"}
                              css={css({
                                transition: "opacity 200ms ease-out",

                                "&.active": {
                                  "> span > span": {
                                    color: "brand.secondary",
                                    fontWeight: 500,
                                  },
                                  "::after": {
                                    width: "100%",
                                  },
                                },
                                "&:hover": { opacity: 0.8 },
                              })}
                            >
                              <Text
                                position="relative"
                                key={"headerLink" + index}
                              >
                                <Link children={label} href={link.url} />
                              </Text>
                            </Box>
                          );
                      })}
                  </HStack>
                </Box>
                <Box
                  display={{ _: "block", lg: "none" }}
                  css={{ transform: "translateY(10px)" }}
                >
                  <Panel
                    panel={{
                      position: "right",
                      children: <Menu data={data} />,
                    }}
                  >
                    <IconButton
                      symbol="menu-line"
                      css={{ ":focus": { highlight: "none" } }}
                    />
                  </Panel>
                </Box>
              </>
            )}
            {data.header_links.length > 1 && (
              <Box py="6px" display={{ _: "none", lg: "block" }} ml="auto">
                <HStack as="ul" space="layout.3">
                  {data.header_links
                    .slice(
                      data.header_links.length - 1,
                      data.header_links.length
                    )
                    .map(({ label, link }, index) => {
                      if (label && link && link.url)
                        return (
                          <Text key={"headerLink" + index} color={"label.0"}>
                            <Link children={label} href={link.url} />
                          </Text>
                        );
                    })}
                </HStack>
              </Box>
            )}
          </Flex>
        </Wrapper>
      </Box>
    </ColorMode>
  );
}

const Ticker = () => {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="https://www.google.com/finance/quote/TPZ:TSE"
    >
      <Box id="quoteWrapper" pr="layout.1">
        <Box
          data-qmod-tool="miniquotes"
          data-qmod-params='{"symbol":"TPZ:CA"}'
          className="qtool"
          css={{
            color: "white",
            ".qmod-ui-tool": {
              fontFamily:
                'Soehne, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            },
            ".qmod-segment": { color: "white !important" },
            ".qmod-exshname": { fontWeight: 600 },
            ".qmod-longname": {
              display: "none",
            },
            ".qmod-last": {
              fontWeight: 600,
              ":before": {
                fontWeight: 600,
                content: "'TPZ: '",
              },
            },
          }}
        />
      </Box>
    </a>
  );
};

const CorporatePresentationLink = ({
  corporate_presentation_file,
  corporate_presentation_label,
}) => {
  return (
    <Box pt={{ _: "3", md: "0" }}>
      {corporate_presentation_file?.url && corporate_presentation_label && (
        <HStack space={{ _: "0", md: "layout.1" }}>
          {/* Divider */}
          <Text size={400} color="white" display={{ _: "none", md: "block" }}>
            {"|"}
          </Text>
          {/* Corporate Presentation Link */}
          <a
            href={corporate_presentation_file?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Text
              size={400}
              color="white"
              css={css({
                cursor: "pointer",
                transition: "color 200ms ease-out",
                "&:hover": {
                  color: "brand.primary",
                },
              })}
            >
              {corporate_presentation_label}
            </Text>
          </a>
        </HStack>
      )}
    </Box>
  );
};

const HEADER_QUERY = graphql`
  {
    page: prismicNav {
      data {
        header_links {
          label
          link {
            type
            url
          }
        }
        corporate_presentation_label
        corporate_presentation_file {
          url
        }
      }
    }
  }
`;

export default Header;
