import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { ThemeProvider, CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { v4 as uuidv4 } from "uuid";

import { createDomMotionComponent, AnimatePresence } from "framer-motion";

// POLYFILLS
import "url-search-params-polyfill";

import "remixicon/fonts/remixicon.css";

import {
  Box,
  ColorMode,
  Footer,
  GlobalStyles,
  Header,
  SEO,
  Theme,
} from "components";

import "./fonts.css";

const motion = {
  div: createDomMotionComponent("div"),
  main: createDomMotionComponent("main"),
};

const myCache = createCache({
  key: "my-cache-key",
  nonce: uuidv4(),
});

function Layout({ children, location }) {
  const { page } = useStaticQuery(SEO_QUERY);
  const { data } = page;

  useEffect(() => {
    // Set static viewport height unit on resize and on load
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <>
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
                (function(w,d){
                  var _mtm = w._mtm = w._mtm || [];
                  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                  var g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                  g.async=true; g.src='https://analytics.imirwin.com/js/container_JmCeo4eY.js'; s.parentNode.insertBefore(g,s);
                })(window, document);
              `,
          }}
        /> */}
        <GlobalStyles />
        <SEO
          title={data.seo_title && data.seo_title.text}
          description={data.seo_description && data.seo_description.text}
          imageOpenGraph={data.seo_image && data.seo_image.url}
          imageTwitter={data.seo_image && data.seo_image.url}
          imageAlt={data.seo_image && data.seo_image.alt}
        />
        <ColorMode mode="light">
          <>
            <Box display="flex" flexDirection="column" minHeight="100vh">
              <Header path={location.pathname} />
              <AnimatePresence>
                <motion.main
                  variants={{
                    initial: {
                      opacity: 0,
                    },
                    enter: {
                      opacity: 1,
                      transition: {
                        duration: 0.3,
                        delay: 0.3,
                      },
                    },
                    exit: {
                      opacity: 0,
                      transition: { duration: 0.2 },
                    },
                  }}
                  key={location.pathname}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                >
                  {children}
                </motion.main>
              </AnimatePresence>
              <Footer />
            </Box>
          </>
        </ColorMode>
      </>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const SEO_QUERY = graphql`
  {
    page: prismicNav {
      data {
        seo_title {
          text
        }
        seo_image {
          url
          alt
        }
        seo_description {
          text
        }
      }
    }
  }
`;

export default Layout;
