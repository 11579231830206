import React, { useState, useEffect, useCallback } from "react";
import { useViewportScroll } from "framer-motion";

function TriggerWrapper({ children, delayInitialCheck, delay, ...rest }) {
  const [show, setShow] = useState(false);
  const [wrapper, setWrapper] = useState(undefined);
  const measuredRef = useCallback(
    (node) => {
      setTimeout(() => {
        const height =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        if (node !== null) {
          if (node.getBoundingClientRect().top < height) {
            setShow(true);
          } else {
            setWrapper(node);
          }
        }
      }, delayInitialCheck);
    },
    [delayInitialCheck]
  );
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    if (wrapper) {
      // On IE, force show state immediately to avoid performance issues
      if (
        navigator.userAgent.indexOf("MSIE") !== -1 ||
        navigator.appVersion.indexOf("Trident/") > -1
      ) {
        setShow(true);
      } else {
        scrollY.onChange((v) => {
          const height =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
          if (
            wrapper.getBoundingClientRect().top < height - height / 4 &&
            !show
          ) {
            setShow(true);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapper]);

  return (
    <div ref={measuredRef} {...rest}>
      {React.cloneElement(children, { show: show })}
    </div>
  );
}

TriggerWrapper.defaultProps = {
  delay: 0,
  delayInitialCheck: 600,
};

export default TriggerWrapper;
