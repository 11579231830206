import React, { useState } from "react";
import css from "@styled-system/css";

import { Box, Heading } from "../../";

function Input({ id, name, label, error, ...rest }) {
  const [isFocused, setFocused] = useState(false);

  return (
    <>
      <Heading
        as="label"
        htmlFor={id}
        size={100}
        className={isFocused ? "isFocused" : undefined}
        display="block"
        color="label.0"
        css={css({
          transition: "color 200ms ease-out",
          "&.isFocused": {
            color: "primary",
          },
        })}
      >
        {label}
      </Heading>
      <Box
        as="input"
        name={name}
        id={id}
        className={isFocused ? "isFocused" : ""}
        width="100%"
        m={0}
        py="spacing.4"
        px={0}
        borderWidth={0}
        borderBottomWidth={2}
        // borderColor="fill.0"
        borderRadius={0}
        fontFamily="sans"
        fontSize={16}
        color="label.0"
        bg="clear"
        css={css({
          transition: "borderColor 200ms ease-out",
          "::placeholder": {
            color: "placeholderText",
            opacity: 1,
          },
          "&:focus": { outline: 0 },
          "&.isFocused": {
            borderColor: "brand.primary",
          },
        })}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
        aria-describedby={name + "error"}
        {...rest}
      />
      <Box
        className={error ? "visible" : ""}
        css={css({
          opacity: 0,
          transition: "opacity 100ms ease-out",
          "&.visible": {
            opacity: 1,
          },
        })}
        role="alert"
        bg="label.0"
      >
        {error && (
          <Heading
            size={100}
            mt={1}
            mb={2}
            id={name + "error"}
            fontWeight={400}
            color="label.0"
          >
            ↳{error}
          </Heading>
        )}
      </Box>
    </>
  );
}

export default Input;
